<template>
  <el-card class="transaction">
    <header @click="!expanded ? expanded = true : null">
      <div class="flex-between">
        <div class="ms-text">{{ data.transactionId }}</div>
        <el-tag
            :type="statusColorClasses[data.status]"
            effect="dark"
            size="mini"
            class="right ml-10">
            {{ data.status }}
          </el-tag>
      </div>

      <div class="flex-between mt-5">
        <div class="ms-text-l">{{ data.accountId }}</div>
        <div class="ms-text text-center">{{ data.entryType }}</div>
        <div class="ms-text-l text-right">
          <template v-if="data.currencyType == 'Assets'">{{ parseFloat(data.assetAmount) }}</template>
          <template v-else>{{ data.amount | money(data.currencyName) }}</template>
        </div>
      </div>

      <div class="flex-between mt-5">
        <div class="ms-text">{{ $date(isTransaction ? data.createdAt : data.reversedAt, 'calendar') }}</div>
        <div class="ms-text text-center">{{ data.currencyName }}</div>
        <div class="ms-text text-right">{{ data.currencyType == 'Assets' ? parseFloat(data.availableAssetsBalance) : $money(data.availableFundsBalance) }}</div>
      </div>
    </header>

    <el-collapse-transition>
      <section v-show="expanded" class="mt-20">
        <data-list :list="infoList" />

        <div class="buttons mt-20">
          <template v-if="['Prime Trust Test', 'Prime Trust'].includes(data.provider)">
            <el-button
              v-if="data.status === 'Pending' || data.status === 'Review'"
              size="small"
              type="info"
              @click="data.transactionType == 'Asset' ? cancelAssetTransaction(data.id) : cancelTransaction(data.id)"
              round>
              Cancel
            </el-button>

            <template v-if="data.status === 'Pending' && data.disbursementAuthorizationsStatus === 'pending'">
              <el-button size="small" type="info" disabled round>Auth</el-button>
            </template>

          </template>
          <template v-else>
            <el-button size="small" type="info" v-if="data.status === 'Review'" @click="cancelTransaction(data.id)" round>Cancel</el-button>
          </template>
        </div>

        <router-link :to="{ name: 'TransactionView', params: { id: data.id } }">
          <div class="text-center mt-10">Details</div>
        </router-link>
      </section>
    </el-collapse-transition>

    <div class="expand-button" @click.stop="expanded = !expanded">
      <i :class="expanded ? 'el-icon-arrow-up' : 'el-icon-arrow-down'" />
    </div>
  </el-card>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      required: true
    },

    isTransaction: {
      type: Boolean,
      default: true
    }
  },

  data () {
    return {
      expanded: false,

      statusColorClasses: {
        New: 'success',
        Complete: 'success',
        Pending: 'warning',
        pending: 'warning',
        Review: 'warning',
        'On Hold': 'warning',
        Reversed: 'info',
        Cancelled: 'black',
        Error: 'danger',
        Declined: 'info',
        Denied: 'danger'
      }
    }
  },

  computed: {
    infoList () {
      return [
        {
          label: 'Reference',
          text: this.data.reference
        }
      ]
    }
  },

  methods: {
    async cancelTransaction (id) {
      if (confirm('Are you sure you want to Cancel this transaction?')) {
        const loader = this.$loading()
        try {
          const status = this.$store.dispatch('transactions/action', { id, action: 'Cancel' })
          this.$alert(status.error ? 'Error: Unable to Cancel Transaction' : 'Transaction Cancelled', 'Confirmation')
          await this.refreshTransactions()
        } catch (e) {
          this.$notify.error(window.errorMessage)
        } finally {
          loader.close()
        }
      }
    },

    async cancelAssetTransaction (id) {
      if (confirm('Are you sure you want to Cancel this transaction?')) {
        const loader = this.$loading()
        try {
          const status = this.$store.dispatch('transactions/action', { id, action: 'CancelAsset' })
          this.$alert(status.error ? 'Error: Unable to Cancel Transaction' : 'Transaction Cancelled', 'Confirmation')
          await this.refreshTransactions()
        } catch (e) {
          this.$notify.error(window.errorMessage)
        } finally {
          loader.close()
        }
      }
    },

    async authRetry (id) {
      const loader = this.$loading()
      try {
        this.$store.dispatch('transactions/action', { id, action: 'AuthRetry' })
        await this.refreshTransactions()
      } catch (e) {
        this.$notify.error(window.errorMessage)
      } finally {
        loader.close()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .transaction {
    header {
      width: 100%;
      .text, .large-text {
        width: 33%;
      }
    }
  }

  .transaction + .transaction {
    margin-top: 15px;
  }
</style>
