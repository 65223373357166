<template>
  <div>
    <error-box
      v-if="error"
      :loading="loading"
      @reload="load()" />

    <div v-else>
      <ms-filters
        :filters="filters"
        :filters-map="filtersMap"
        @apply="applyFilters($event)" />

      <pull-to :top-load-method="reload">
        <div>
          <el-button :type="tab === 'REPORTING' ? 'info' : undefined" size="medium" @click="tab = 'REPORTING'" round>Reporting</el-button>
          <el-button :type="tab === 'REVERSALS' ? 'info' : undefined" size="medium" @click="tab = 'REVERSALS'" round>Reversals</el-button>
        </div>

        <div
          v-if="loading"
          v-loading="loading"
          class="page-loader" />

        <div v-if="!!transactions.length" class="mt-20">
          <transaction-item v-for="transaction in transactions" :key="transaction.id" :data="transaction" :is-transaction="tab === 'REPORTING'" />
        </div>

        <empty v-else>There are no transactions found.</empty>

        <template slot="top-block">
          <div>
            <div
              v-if="reloading"
              v-loading="reloading"
              class="basic-loader" />
          </div>
        </template>
      </pull-to>
    </div>
  </div>
</template>

<script>
import moment from 'moment'

import PullTo from 'vue-pull-to'
import TransactionItem from '../../components/transactions/TransactionItem'

export default {
  components: {
    PullTo,
    TransactionItem
  },

  data () {
    return {
      tab: 'REPORTING',

      filters: null,

      currencyTypes: window.currencyTypesOptions,
      entryTypes: window.entryTypesOptions,
      transactionTypes: window.transactionTypesOptions,
      transactionStatuses: window.transactionStatusesOptions,

      reporting: [],
      reversals: [],

      reportingTotal: 0,
      reversalsTotal: 0,

      error: null,
      loading: true,
      reloading: false
    }
  },

  computed: {
    filtersMap () {
      return [
        {
          key: 'date',
          label: 'Date Range',
          type: 'range_datepicker'
        },
        {
          key: 'entryType',
          label: 'Entry',
          type: 'select',
          options: this.entryTypes
        },
        {
          key: 'depositType',
          label: 'Type',
          type: 'select',
          options: this.transactionTypes
        },
        {
          key: 'amount',
          label: 'Amount',
          type: 'input'
        },
        {
          key: 'status',
          label: 'Status',
          type: 'select',
          options: this.transactionStatuses
        }
      ]
    },

    currencies () {
      return this.$store.state.app.currencies.map(currency => ({
        id: currency.id,
        text: currency.currencyNickname
      }))
    },

    user () {
      return this.$store.state.app.user
    },

    registration () {
      return this.user?.registration || null
    },

    accounts () {
      return this.registration?.accounts || []
    },

    transactions () {
      return this.tab === 'REPORTING' ? this.reporting : this.reversals
    }
  },

  created () {
    this.$store.commit('page/setPageInfo', {
      title: 'Transactions',
      back: { name: 'Home' }
    })

    this.load()
    this.load(true)
  },

  methods: {
    applyFilters (filters) {
      this.filters = filters
      this.load(this.tab === 'REPORTING')
    },

    getOrdersAndParams (isReporting = false) {
      const params = {}
      let order = {}
      if (!this.filters || !Object.values(this.filters).filter(val => val).length) {
        params.accountId = ['not like', '9%']
        if (isReporting) {
          params.createdAt = ['>=', moment('00:00:00', 'HH:mm:ss').format('YYYY-MM-DD HH:mm:ss')]
        } else {
          params.reversedAt = ['>=', moment('00:00:00', 'HH:mm:ss').format('YYYY-MM-DD HH:mm:ss')]
        }
        order = isReporting ? ['createdAt:DESC', 'transactionId:DESC'] : ['reversedAt:DESC', 'transactionId:DESC']
      } else {
        params.accountId = ['not like', '9%']

        const fromDateKey = isReporting ? 'createdAtAfter' : 'reversedAtAfter'
        const toDateKey = isReporting ? 'createdAtBefore' : 'reversedAtBefore'
        const dateFrom = this.filters.date?.from ? this.filters.date.from : null
        const dateTo = this.filters.date?.to ? this.filters.date.to : null
        if (dateFrom) { params[fromDateKey] = ['>=', dateFrom + ' 00:00:00', 'createdAt'] }
        if (dateTo) { params[toDateKey] = ['<=', dateTo + ' 23:59:59', 'createdAt'] }

        if (this.filters.depositType) {
          params.transactionType = this.filters.depositType
        }

        if (this.filters.entryType) {
          params.entryType = this.filters.entryType
        }

        if (this.filters.profileId) {
          params.profileId = this.filters.profileId
        }

        if (this.filters.transactionId) {
          params.transactionId = this.filters.transactionId
        }

        if (this.filters.accountId) {
          params.accountId = this.filters.accountId
        }

        if (this.filters.amount) {
          params.amount = this.filters.amount
        }

        if (isReporting) {
          if (this.filters.status) {
            params.status = this.filters.status
          }
        } else {
          params.status = 'Reversed'
          if (this.filters.status) {
            params.status = this.filters.status !== 'Reversed' ? 'Null' : 'Reversed'
          }
        }

        if (this.filters.currencyType) {
          params.currencyType = this.filters.currencyType
        }

        if (this.filters.currencyName) {
          params.currencyName = this.filters.currencyName
        }

        order = ['transactionId:DESC']
      }

      params.accountId = ['OR']
      this.accounts.forEach(account => {
        params.accountId.push(account.accountNumber)
      })

      return {
        order,
        params
      }
    },

    async load (isReporting = true) {
      this.loading = true
      try {
        const { order, params } = this.getOrdersAndParams(isReporting)
        const { items } = await this.$store.dispatch('transactions/load', {
          returnRaw: true,
          params,
          order,
          skip: ['profileId', 'provider', 'providerTransactionId', 'completedBy', 'transactionFee', 'totalAmount', 'instructions', 'fundsTransferId', 'bankName', 'accountName', 'accountType', 'routingNumber', 'accountNumber', 'bankAccountType', 'statusDate', 'notes']
        })

        this[isReporting ? 'reporting' : 'reversals'] = items

        this.error = false
      } catch {
        this.error = true
      } finally {
        this.loading = false
      }
    },

    async reload (loaded) {
      const isReporting = this.tab === 'REPORTING'
      this.reloading = true
      try {
        const { order, params } = this.getOrdersAndParams(isReporting)
        const { items } = await this.$store.dispatch('transactions/load', {
          returnRaw: true,
          params,
          order,
          skip: ['profileId', 'provider', 'providerTransactionId', 'completedBy', 'transactionFee', 'totalAmount', 'instructions', 'fundsTransferId', 'bankName', 'accountName', 'accountType', 'routingNumber', 'accountNumber', 'bankAccountType', 'statusDate', 'notes']
        })

        this[isReporting ? 'reporting' : 'reversals'] = items

        this.error = false
      } catch (e) {
        this.error = true
        console.error(e)
      } finally {
        loaded('done')
        this.reloading = false
      }
    }
  }
}
</script>
